/* HEADER STYLES–––––––––––––––––––––––––––––––––––––––––––––––––– */
.custom-link-sidebar{
    display: flex;
    padding: .5rem;
    font-size: 1.05rem;
    border-radius: .6px;
    align-items: center;

}
.hor-menu-icon{
    font-size: 20px;
}
.custom-link, 
.custom-link-active{
    border-bottom-width: 2px;
    border-color: transparent;
    display: flex;
    padding: 1.5rem 1.25rem;
    align-items: center;
    line-height: 1.25rem;
    color: rgb(156, 163, 175);
    transition-duration: 150ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.custom-link:focus{
    color: var(--main-light-blue);
    border-color: rgba(59, 130, 246);
}
.custom-link:hover {
    background-color: rgb(229, 231, 235);    
}
.custom-link-active{
    background-color: var(--double-light-blue);
    color: var(--main-blue);
    font-weight: 600;
}
.custom-link-active .hor-menu-icon{
    color: var(--main-blue);
}
.menu-element li .custom-link-sidebar,
.submenu li .custom-link-sidebar{
    color: rgb(107 114 128)
}
.menu-element li .custom-link-sidebar .menu-icon,
.submenu li .custom-link-sidebar .menu-icon{
    font-size: 1.2rem;
}

.menu-element li .custom-link-sidebar.active-link,
.submenu li .custom-link-sidebar.active-link{
    background: var(--double-light-blue);
    outline: none;
    color: var(--main-blue);
    border-radius: 7px;
}

.menu-element li .custom-link-sidebar:hover,
.submenu li .custom-link-sidebar:hover{
    background: rgb(243 244 246);
    border-radius: 7px;
    color: var(--main-blue);
    outline: none;
}

.menu-element li .custom-link-sidebar.active-link .menu-icon,
.submenu li .custom-link-sidebar.active-link .menu-icon{
    color: var(--main-blue);
}

.menu-element li .custom-link-sidebar:hover .menu-icon,
.submenu li .custom-link-sidebar:hover .menu-icon{
    color: var(--main-blue);
}


/* Horizontal navbar styles */
.page-container{
    position: relative;
    width: calc(100% - var(--page-header-width));
    left: var(--page-header-width);
}
.horizontal-nav{
    left: var(--page-header-width);
    box-shadow: 0px 5px 5px -5px rgba(0, 0, 0, 0.2);
}
.main-container-wrapper{
    position: relative;
    min-height: calc(100vh - 72px - 2rem);
}

/* PAGE CONTENT STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.page-content {
    position: relative;
    left: var(--page-header-width);
    width: calc(100% - var(--page-header-width));
    min-height: 100vh;
    padding: 0 30px;
    color: var(--page-content-txt-color);
    background: var(--page-content-bg-color);
}

