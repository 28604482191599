a.link-disabled{
    pointer-events: none;
    opacity: .5;
    cursor: not-allowed;
}

.footer{
    width: 100%;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #212529;
}
.footer p{
    color: #fff;
    font-size: 1rem;
}

/* ===== Loading content spinner styles ===== */

@media print {
    .hide-to-print {
        display: none;
    }
}

.required-input{
    color: var(--danger);
    font-size: 16px;
}
.input-error{
    font-size: 13px;
    color: var(--danger);
    font-weight: bold
}

/* ===== Webkits Scrollbar styles =====*/
::-webkit-scrollbar{
    width: 4px;
    background-color: var(--color-white);
}
::-webkit-scrollbar-thumb{
    /* border-radius: 4px; */
    background-color: var(--color-black);
}

::-webkit-scrollbar-track{
    box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    /* border-radius: 4px; */
    background-color: var(--color-white);
}
/* Scrollbar horizontal */
::-webkit-scrollbar:horizontal {
    height: 4px;
    background-color: var(--color-white);
}

::-webkit-scrollbar-thumb:horizontal {
    background-color: var(--color-black);
}

::-webkit-scrollbar-track:horizontal {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: var(--color-white);
}

header.page-header::-webkit-scrollbar-thumb{
    background-color: #f0f1f6;

}


.more-btn{
    background-color: transparent;
}

.more-btn-dropdown.show {
  display: block;
}
.more-btn-icon{
    font-size: 1.2rem;
    text-align: center;
    color: var(--main-blue);
}
.modal{
    transition: all .4s ease-in-out;
    height: 100vh;
    background-color: rgba(0, 0, 0, .5);
}