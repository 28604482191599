@layer primereact, tailwind-base, tailwind-utilities;

@import 'primereact/resources/primereact.min.css' layer(primereact);
@import 'primereact/resources/themes/tailwind-light/theme.css' layer(primereact);

@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
}


/*===== GENERAL STYLES =====*/
@font-face {
    font-family: 'LibreFranklin';
    src: url('./assets/fonts/LibreFranklin-Regular.woff2') format('woff');
}
:root {
    --main-blue: #0000BB;
    --main-light-blue: #7C7CD5;
    --double-light-blue: #D9D9FF;
    --triple-light-blue: #EBEBF4;
    --main-green: #99d5c9;
    --main-light-green: #C4EDC4;

    --main-font: "LibreFranklin", sans-serif;
    --cursive-font: "LibreFranklin" , cursive;

    --success: #219653;
    --danger: #D34053;
    --warning: #FFA70B;
    --info:#17A2B4;

    --big-font-size: 2rem;
    --normal-font-size: 1rem;
    --smaller-font-size: 0.875rem;

    --main-background: #f1f5f9;
  
    --sidebar-width: 14rem;
    --sidebar-width-check: 5rem;
  
    --box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);

    /* Dashboard */
    --menu-option-bg-active: var(--main-light-blue);
    --page-header-txt-color: #0D1321;
    --page-header-width: 240px;
    --page-content-bg-color: #f1f5f9;
    --page-content-txt-color: #0D1321;
}


@media screen and (min-width: 768px){
    :root{
        --big-font-size: 2.5rem;
        --normal-font-size: 1rem;
    }  
}

*,
*::after,
*::before{
    box-sizing: border-box;
}
/* Overwrite PrimeReact styles */
.p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody > tr > td,
.p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-thead > tr > th {
    border-width: 1px 1px 1px 1px !important;
}
.fc-today-button.fc-button.fc-button-primary{
    /* background-color: var(--main-green) !important; */
}

.fc-prev-button.fc-button.fc-button-primary,
.fc-next-button.fc-button.fc-button-primary{
    background-color: transparent !important;
    border: 1px solid var(--main-green) !important;
    color: var(--main-green) !important;
}
.fc-day.fc-day-today.fc-daygrid-day {
    background-color: var(--main-green) !important;
}
/* Picklist buttons styles */
.p-picklist .p-picklist-buttons .p-button {
    background-color: var(--main-light-blue);
    border: none;
    color: #fff;
    margin-bottom: .5rem;
    padding: .5rem 0;
}

.p-picklist .p-picklist-buttons .p-button:hover {
    background-color: #005bb5;
    border: none;
    color: #fff;
}

.p-picklist .p-picklist-buttons .p-button .pi {
    font-size: 2rem;
}
.p-tabview-nav-next.p-tabview-nav-btn.p-link {
    background-color: whitesmoke;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
    border-width: 0 0 2px 0 !important;
    border-color: transparent transparent #e5e7eb transparent !important;
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    border-color: #4f46e5 !important;
    color: #4f46e5 !important;
}
.p-tabview .p-tabview-nav{
    border-width: 0 0 2px 0 !important;
}
body{
    font-family: var(--main-font);
    line-height: 1.5;
    color: var(--text-black);
    font-size: 1rem;
    box-sizing: border-box;
    margin: 0;
}

html{
    scroll-behavior: smooth;
}
a{
    text-decoration: none;
}
label {
    cursor: pointer;
}
button[type="submit"]:not(.with-border) {
    border: none;
}
input:disabled, .p-disabled {
    color: black !important;
}
